/* Footer styling */
.footer {
  padding: 30px 0 0px 0;
  border-top: 1px solid var(--mainGrey);
  background-image: url("https://www.transparenttextures.com/patterns/inspiration-geometry.png");
}

/* Format to-top button */
.to-top {
  color: var(--mainGrey);
  opacity: 0;
}

/* Apply animation to to-top button when appears */
.to-top.animate {
  animation-name: fadeInUpward;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

/* to-top button hover */
.to-top:hover {
  color: rgb(81, 157, 193);
  transform: translateY(-10px);
  transition: all ease-in 0.3s;
}

/* to-top button text */
.to-top p {
  text-align: center;
  color: rgb(81, 157, 193);
  opacity: 0;
  transition: all ease-in 0.3s;
}

/* Make button appear when on screen */
.to-top.show {
  visibility: visible;
}

/* Make text appear on hover */
.to-top.show p {
  opacity: 100;
}

.footer .logo {
  width: 180px;
}

/* social icons & expanding circle hover effect */
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgb(0, 0, 0);
  display: inline-flex;
  border-radius: 50%;
  margin: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.social-icon {
  display: inline-block;
}

.social-icon a::before {
  content: "";
  width: 44px;
  height: 44px;
  position: absolute;
  background-color: var(--mainBlue);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 50%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(0%) invert(0%) sepia(0%) saturate(0%)
    hue-rotate(346deg) brightness(0%) contrast(50%);
}

/* Copyright text */
.copyright {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: end;
  padding: 10px 0 0 0;
  margin: 0 0 10px 0;
  color: var(--mainGrey);
}

/* Animation for to-top button */
@keyframes fadeInUpward {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 770px) {
  .footer p {
    text-align: center;
  }
}
