/* NavBar formatting */
nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: #fff;
  border-bottom: 1px solid var(--mainGrey);
  min-height: 90px;
  transition: transform 0.3s ease-in-out;
}

/* Position for navBar when page is scrolled */
.hidden-navbar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

/* Remove border from toggle icon */
nav.navbar .navbar-toggler {
  border: none;
}

/* Remove styling from toggle icon on click */
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

/* Formatting bottom line of menu icon */
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 18px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid rgb(81, 157, 193);
  z-index: 10;
  transition: all 300ms linear;
}

/* Formatting of navbar menu items */
nav.navbar .navbar-nav .nav-link.navbar-link {
  display: inline-block;
  font-weight: 400;
  letter-spacing: 0.8px;
  font-size: 20px;
  color: black;
  position: relative;
  overflow: hidden;
}

/* underline on hover */
nav.navbar .navbar-nav .nav-link.navbar-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 9px;
  width: 0;
  height: 1px;
  background-color: rgb(81, 157, 193);
  transition: width 0.25s ease;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover {
  color: rgb(81, 157, 193);
}

nav.navbar .navbar-nav .nav-link.navbar-link:hover::after {
  width: 100%;
}

/* Formatting top two lines of menu icon */
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: rgb(81, 157, 193);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

/* Position middle burger menu line */
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

/* Turn burger menu into an X */
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-bottom: solid 2px #00000000;
}

/* Spacing between navbar menu items */
.ms-auto {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Positioning the burger menu and background circle together */
.custom-navbar-toggler {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.logo {
  width: 200px;
}

/* Creates the circle */
.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--mainBlue);
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translate(50%, -50%);
  z-index: -1;
}

/* Media queries */

@media (max-width: 1000px) {
  /* disable scrolling effect for tablets/ mobiles */
  .hidden-navbar {
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }

  /* Formatting of navbar menu items */
  .ms-auto {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  /* Positing of underline on hover */
  nav.navbar .navbar-nav .nav-link.navbar-link::after {
    left: 40%;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link:hover::after {
    width: 20%;
  }
}

@media (max-width: 580px) {
  /* Aligning burger menu */
  .full-navbar {
    margin-right: 12px !important;
  }
}

@media (max-width: 450px) {
  .logo {
    width: 70vw;
    height: auto;
    padding: 10px 0;
  }
  .full-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 340px) {
  .logo {
    width: 60vw;
  }
}
