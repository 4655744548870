:root {
  --mainPink: rgb(255, 240, 255);
  --mainBlue: rgb(227, 246, 255);
  --mainBeige: rgb(250, 246, 235);
  --mainGrey: rgb(168, 168, 168);
  --mainPinkOpaque: rgba(255, 240, 255, 0.661);
  --mainBlueOpaque: rgba(188, 221, 234, 0.525);
}

/* Custom Fonts */
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* Default CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p:hover,
h1:hover,
h2:hover,
h3:hover {
  cursor: default;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

p {
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
