.contact {
  padding: 20px 0;
  background-color: var(--mainBlue);
  text-align: center;
  padding: 60px 50px;
  border-top: 1px solid var(--mainGrey);
}

.contact h1 {
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(#ffffff77);
  border: 1px solid var(--mainPink);
  color: #000;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact .button {
  margin: 20px 0;
}

.contact .error {
  border: solid red 1px;
}

.contact .error-message {
  color: red;
}
