/* Align components */
.message-sent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 108px;
  transition: all 0.5s ease;
}

.message-sent-content {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

/* Size of envelope GIF */
.envelope {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
}

/* Align message text */
.message-sent-text {
  display: flex;
  align-items: center;
  margin-top: 18px;
  animation: message-sent-text-animation 0.5s forwards linear;
}

/* Format message text */
h5 {
  font-weight: 400 !important;
  letter-spacing: 0.8px;
  font-size: 20px !important;
  vertical-align: middle;
  color: #000;
  text-decoration: underline;
  white-space: nowrap;
}

/* Animate button appear */
@keyframes message-sent-text-animation {
  0% {
    transform: translate(-20px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
