/* Remove default styling */
button {
  background-color: #00000000;
  border: none;
}

.button {
  position: relative;
  margin: 0 20px;
  padding: 19px;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
}

.button:hover {
  cursor: pointer;
}

/* Alignment of button components */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button a {
  text-decoration: underline;
}

.button.pink:before {
  background: var(--mainPink);
}
.button.blue:before {
  background: var(--mainBlue);
}

/* Create a circle */
.button:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  transition: all 0.3s ease;
  transform-origin: left;
}

/* Format button text */
.button span {
  position: relative;
  font-weight: 400 !important;
  letter-spacing: 0.8px;
  font-size: 20px;
  vertical-align: middle;
  color: #000;
  text-decoration: underline;
}

/* Format the arrow */
.button svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: inherit;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.button.pink:hover:before {
  background: var(--mainPink);
}

.button.blue:hover:before {
  background: var(--mainBlue);
}

/* Turn into rectangle on hover */
.button:hover:before {
  width: 100%;
  border-radius: 0;
}

/* Arrow animation */
.button:hover svg {
  transform: translateX(0);
}

/* Media Queries - remove margin, reduce text size, reduce size of circle */
@media (max-width: 440px) {
  .project-button {
    margin: 0;
  }
  .project-button::before {
    width: 45px;
    height: 45px;
  }
  .project-button span {
    font-size: 14px;
  }
}
