.banner {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  margin-top: 80px;
}

.gif {
  margin-top: 140px;
  max-width: 130vh;
}

/* Media Queries */
@media screen and (max-width: 1000px) {
  .gif {
    max-width: 90vh;
  }
}

@media screen and (max-width: 1000px) {
  .gif {
    max-width: 90vh;
  }
}

@media (max-width: 700px) {
  .gif {
    width: 145vw;
    object-fit: cover; /* Maintain aspect ratio without distortion */
    margin-top: 0px;
  }
}
