/* Skills styling */
.skills {
  padding: 50px 0;
  border-bottom: 1px solid var(--mainGrey);
  border-top: 1px solid var(--mainGrey);
  background-image: url("https://www.transparenttextures.com/patterns/inspiration-geometry.png");
}

/* Format the two logos-groups to correct wrapping for various screen sizes */
.logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

/* spacing of logos inside logos-group */
.logos-group {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.skills p {
  padding: 20px 12px;
}

/* Format logo images */
.skills img {
  height: 80px;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  opacity: 85%;
}
.skills img:hover {
  filter: grayscale(0%);
  opacity: 100%;
  transform: scale(105%);
  transition: all ease-in 0.2s;
}

/* Animation */
.logo-wrapper {
  position: relative;
  transform: scale(0);
  transition: transform 0.3s ease-out;
}
.logo-wrapper.in-view {
  animation: scaleIn 300ms linear forwards;
}
@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* Format text to show on hover of logos*/
.skills .logo-wrapper::after {
  content: attr(data-text);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  color: rgb(255, 180, 255);
}

.skills .logo-wrapper:hover::after {
  opacity: 1;
}

/* Media Queries */
@media (max-width: 1000px) {
  .skills img {
    height: 60px;
  }
  .logos-group {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .skills img {
    height: 40px;
  }
}
