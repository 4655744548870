/* Layout of project cards */
.projects {
  padding: 50px 0;
}
.projects .row {
  display: flex;
  justify-content: center;
}

/* Format each project */
.project {
  margin: 12px 0;
  position: relative;
  overflow: hidden;
  transition: all 0.8s ease;
  align-self: center;
  justify-self: center;
}

.project-image {
  max-width: 100%;
  display: block;
}

.project-overlay {
  background-color: rgba(188, 221, 234, 0.878);
  position: absolute;
  top: -200%;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s ease-out;
}

/* Show project (on hover or mobile on click) */
.show-project {
  top: 0;
  opacity: 1;
}
.project-overlay-content {
  opacity: 0;
  padding: 100px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  transform: translateY(-20px);
  transition: all 0.2s ease 0s;
}

/* Show content on hover */
.show-content {
  transform: translateY(0px);
  transition: all 0.4s ease-out 0.5s;
  opacity: 1;
}

/* Icons */
.skill-image {
  height: 45px;
  width: auto;
  margin: 10px;
}

/* Close button for mobile */
.close {
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 45px;
}
/* hide close button for desktops */
button.hide {
  display: none;
}

/* Media Queries */
@media (max-width: 1000px) {
  .project-overlay-content {
    padding: 50px 20px;
  }
}

@media (max-width: 450px) {
  .close {
    top: 0px;
    right: 20px;
    font-size: 35px;
  }
}

@media (max-width: 355px) {
  .skill-image {
    display: none;
  }
}

@media (max-width: 320px) {
  .project p {
    font-size: 14px;
    margin: 0 0 8px 0;
  }
}
