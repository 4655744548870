.about {
  padding: 50px 12px;
  position: relative;
  background-image: url("https://www.transparenttextures.com/patterns/inspiration-geometry.png");
  border-top: 1px solid var(--mainGrey);
}

.about img {
  border-radius: 100%;
  padding: 20px;
  max-height: 450px;
  max-width: 450px;
}

.key-words {
  font-weight: 500;
  color: rgb(81, 157, 193);
}
